<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Sincronización de productos</div>
    </div>

    <div class="uk-child-width-1-2 uk-margin-small-top" uk-grid>
      <div uk-form-custom>
        <input
          type="file"
          @change="
            handleFile(
              $event.target.files[0],
              '/product-sync/promoopcion/inventory'
            )
          "
        />
        <div
          class="uk-padding dashed-border uk-border-rounded uk-text-large uk-text-center"
        >
          <div>
            <span uk-icon="icon: cloud-upload; ratio: 3"></span>
          </div>
          <div>Espacio de carga de productos de Promoopción</div>
        </div>
      </div>

      <div uk-form-custom>
        <input
          type="file"
          @change="
            handleFile(
              $event.target.files[0],
              '/product-sync/promoopcion/prices'
            )
          "
        />
        <div
          class="uk-padding dashed-border uk-border-rounded uk-text-large uk-text-center"
        >
          <div>
            <span uk-icon="icon: cloud-upload; ratio: 3"></span>
          </div>
          <div>Espacio de carga de precios de Promoopción</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  name: "ProductSyncIndex",

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    handleFile(file, path) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", file);
      this.axios
        .post(path, formData)
        .then(() => {
          UIkit.notification({
            message:
              "<div class='uk-flex uk-flex-middle'><div uk-icon='check'></div><div class='uk-margin-small-left'>Archivo procesado</div></div>",
            status: "success",
            pos: "bottom-left",
          });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
